import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {languageText} from "../../../languageText/headerFooter"
import LangContext from "../context/lang-context";
import { useContext } from "react";

const Footer = () => {

  const [footerTool, setFooterTool] = useState("")
  const [footerStat, setFooterStat] = useState("")
  const [footerEdu, setFooterEdu] = useState("")
  const [footerNews, setFooterNews] = useState("")
  const [siteLang , setSiteLang] = useState("")
  const langContext = useContext(LangContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, [langContext.lang]);

  const footerToolMenu = () => {
    if(footerTool == ""){
      setFooterTool("in")
    }else{
      setFooterTool("")
    }
  }

  const footerStatMenu = () => {
    if(footerStat == ""){
      setFooterStat("in")
    }else{
      setFooterStat("")
    }
  }

  const footerEduMenu = () => {
    if(footerEdu == ""){
      setFooterEdu("in")
    }else{
      setFooterEdu("")
    }
  }

  const footerNewsMenu = () => {
    if(footerNews == ""){
      setFooterNews("in")
    }else{
      setFooterNews("")
    }
  }

  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="sitemap sitemap hidden-xs hidden-sm">
          <div className="sitemap-col">
            <h3 className="color-primary">
              {siteLang == "eng" ? languageText.toolHeadingEn : languageText.toolHeading}
            </h3>
            <ul>
              <li>
                <Link to="/tools/dwsearch">
                  {siteLang == "eng" ? languageText.dwSearchEn : languageText.dwSearch}
                </Link>
              </li>
              <li>
                <Link to="/tools/livematrix">
                  {siteLang == "eng" ? languageText.liveMatrixEn : languageText.liveMatrix}
                </Link>
              </li>
              <li>
                <Link to="/tools/underlying">
                  {(siteLang == "eng") ? languageText.foreignIndexChartEn : languageText.foreignIndexChart}
                </Link>
              </li>
              <li>
                <Link to="/tools/dwterms">
                  {siteLang == "eng" ? languageText.dwTermsEn : languageText.dwTerms}
                </Link>
              </li>
              {/* <li>
                <Link to="/tools/dwcalculator">
                  {siteLang == "eng" ? languageText.dwCalculatorEn : languageText.dwCalculator}
                </Link>
              </li> */}
              <li>
                <Link to="/tools/dwcompare">
                  {siteLang == "eng" ? languageText.dwComparisonEn : languageText.dwComparison}
                </Link>
              </li>
              <li>
                <Link to="/tools/dwranking">
                  {siteLang == "eng" ? languageText.dwRankingEn : languageText.dwRanking}
                </Link>
              </li>
            </ul>
          </div>
          <div className="sitemap-col">
            <h3 className="color-primary">
              {siteLang == "eng" ? languageText.statHeadingEn : languageText.statHeading}
            </h3>
            <ul>
              <li>
                <Link to="/marketdata/toptradedstock">
                  {siteLang == "eng" ? languageText.topTradedStockEn : languageText.topTradedStock}
                </Link>
              </li>
              <li>
                <Link to="/marketdata/toptradewarrants">
                  {siteLang == "eng" ? languageText.topTradedWarrantsEn : languageText.topTradedWarrants}
                </Link>
              </li>
              <li>
                <Link to="/marketdata/turnoverdist">
                  {siteLang == "eng" ? languageText.dwTurnoverDistributionEn : languageText.dwTurnoverDistribution}
                </Link>
              </li>
              <li>
                <Link to="/marketdata/dwturnover">
                  {siteLang == "eng" ? languageText.dwMarketTurnoverEn : languageText.dwMarketTurnover}
                </Link>
              </li>
            </ul>
          </div>
          <div className="sitemap-col">
            <h3 className="color-primary">
              {siteLang == "eng" ? languageText.eduHeadingEn : languageText.eduHeading}
            </h3>
            <ul>
              <li>
                <Link to="/education/landing">
                  {siteLang == "eng" ? languageText.Boost : languageText.Boost}
                </Link>
              </li>
              <li>
                <Link to="/education/dwtips">
                  {siteLang == "eng" ? languageText.dwTipsEn : languageText.dwTips}
                </Link>
              </li>
              <li>
                <Link to="/education/dwvideo">
                  {siteLang == "eng" ? languageText.dwVideoEn : languageText.dwVideo}
                </Link>
              </li>
              <li>
                <Link to="/eduhtml">
                  {siteLang == "eng" ? languageText.investorAcademyEn : languageText.investorAcademy}
                </Link>
              </li>
              <li>
                <Link to="/education/indexdw">
                  {siteLang == "eng" ? languageText.indexDWEn : languageText.indexDW}
                </Link>
              </li>
              <li>
                <Link to="/education/faq">
                  {siteLang == "eng" ? languageText.faqEn : languageText.faq}
                </Link>
              </li>
              <li>
                <Link to="/education/whydw28">
                  {siteLang == "eng" ? languageText.whyDW28En : languageText.whyDW28}
                </Link>
              </li>
              <li>
                <Link to="/education/seminar">
                  {siteLang == "eng" ? languageText.seminarEn : languageText.seminar}
                </Link>
              </li>
            </ul>
          </div>
          <div className="sitemap-col clearfix">
            <h3 className="color-primary">
              {siteLang == "eng" ? languageText.newsHeadingEn : languageText.newsHeading}
            </h3>
            <ul>
              <li>
                <Link to="/marketnews/dailysinglestock">
                  {siteLang == "eng" ? languageText.singleStockUpdateEn : languageText.singleStockUpdate}
                </Link>
              </li>
              {/* <li>
                <Link to="/marketnews/dailysp500">
                  {siteLang == "eng" ? languageText.dailySpEn : languageText.dailySp}
                </Link>
              </li> */}
              <li>
                <Link to="/marketnews/dailyhsi">
                  {siteLang == "eng" ? languageText.dailyHsiEn : languageText.dailyHsi}
                </Link>
              </li>
              <li>
                <Link to="/marketnews/todayhighlight">
                  {siteLang == "eng" ? languageText.todayHighlightEn : languageText.todayHighlight}
                </Link>
              </li>
              {/* <li>
                <Link to="/marketnews/dailycommentary">
                  {siteLang == "eng" ? languageText.dailyCommentryEn : languageText.dailyCommentry}
                </Link>
              </li> */}
              {/* <li>
                <Link to="/marketnews/overnightmarkets">
                  {siteLang == "eng" ? languageText.overnightMarketEn : languageText.overnightMarket}
                </Link>
              </li> */}
              <li>
                <Link to="/marketnews/subscribe">
                  {siteLang == "eng" ? languageText.subscribeEn : languageText.subscribe}
                </Link>
              </li>
              <li>
                <Link to="/marketnews/activities">
                  {siteLang == "eng" ? languageText.activitiesEn : languageText.activities}
                </Link>
              </li>
              <li>
                <Link to="/marketnews/photogallery">
                  {siteLang == "eng" ? languageText.photoGalleryEn : languageText.photoGallery}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div style={{clear:"both"}}></div>
        <div className="sitemap-xs visible-xs visible-sm">
          <div className="sitemap-row">
            <div className="sitemap-accordion">
              <div className="sitemap-panel">
                <div className="sitemap-panel-heading">
                  <div className="sitemap-panel-title">
                    <button className="collapsed-title" onClick={footerToolMenu}>
                      <i className="fa fa-angle-down float-right"></i>
                      {siteLang == "eng" ? languageText.toolHeadingEn : languageText.toolHeading}
                    </button>    
                  </div>
                </div>
                <div id="sm1" className={`sitemap-panel-collapse collapse ${footerTool}`}>
                  <div className="sitemap-panel-body">
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/dwsearch">
                        {siteLang == "eng" ? languageText.dwSearchEn : languageText.dwSearch}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/livematrix">
                        {siteLang == "eng" ? languageText.liveMatrixEn : languageText.liveMatrix}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/underlying">
                        {(siteLang == "eng") ? languageText.foreignIndexChartEn : languageText.foreignIndexChart}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/dwterms">
                        {siteLang == "eng" ? languageText.dwTermsEn : languageText.dwTerms}
                      </Link>
                    </div>
                    {/* <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/dwcalculator">
                        {siteLang == "eng" ? languageText.dwCalculatorEn : languageText.dwCalculator}
                      </Link>
                    </div> */}
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/dwcompare">
                        {siteLang == "eng" ? languageText.dwComparisonEn : languageText.dwComparison}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/tools/dwranking">
                        {siteLang == "eng" ? languageText.dwRankingEn : languageText.dwRanking}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sitemap-row">
            <div className="sitemap-accordion">
              <div className="sitemap-panel">
                <div className="sitemap-panel-heading">
                  <div className="sitemap-panel-title">
                    <button className="collapsed-title" onClick={footerStatMenu}>
                      <i className="fa fa-angle-down float-right"></i>
                      {siteLang == "eng" ? languageText.statHeadingEn : languageText.statHeading}
                    </button>    
                  </div>
                </div>
                <div id="sm2" className={`sitemap-panel-collapse collapse ${footerStat}`}>
                  <div className="sitemap-panel-body">
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketdata/toptradedstock">
                        {siteLang == "eng" ? languageText.topTradedStockEn : languageText.topTradedStock}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketdata/toptradewarrants">
                        {siteLang == "eng" ? languageText.topTradedWarrantsEn : languageText.topTradedWarrants}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketdata/turnoverdist">
                        {siteLang == "eng" ? languageText.dwTurnoverDistributionEn : languageText.dwTurnoverDistribution}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketdata/dwturnover">
                        {siteLang == "eng" ? languageText.dwMarketTurnoverEn : languageText.dwMarketTurnover}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sitemap-row">
            <div className="sitemap-accordion">
              <div className="sitemap-panel">
                <div className="sitemap-panel-heading">
                  <div className="sitemap-panel-title">
                    <button className="collapsed-title" onClick={footerEduMenu}>
                      <i className="fa fa-angle-down float-right"></i>
                      {siteLang == "eng" ? languageText.eduHeadingEn : languageText.eduHeading}
                    </button>
                  </div>
                </div>
                <div id="sm3" className={`sitemap-panel-collapse collapse ${footerEdu}`}>
                  <div className="sitemap-panel-body">
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/landing">
                        {siteLang == "eng" ? languageText.Boost : languageText.Boost}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/dwtips">
                        {siteLang == "eng" ? languageText.dwTipsEn : languageText.dwTips}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/dwvideo">
                        {siteLang == "eng" ? languageText.dwVideoEn : languageText.dwVideo}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/eduhtml">
                        {siteLang == "eng" ? languageText.investorAcademyEn : languageText.investorAcademy}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/indexdw">
                        {siteLang == "eng" ? languageText.indexDWEn : languageText.indexDW}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/faq">
                        {siteLang == "eng" ? languageText.faqEn : languageText.faq}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/whydw28">
                        {siteLang == "eng" ? languageText.whyDW28En : languageText.whyDW28}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/education/seminar">
                        {siteLang == "eng" ? languageText.seminarEn : languageText.seminar}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sitemap-row">
            <div className="sitemap-accordion">
              <div className="sitemap-panel">
                <div className="sitemap-panel-heading">
                  <div className="sitemap-panel-title">
                    <button className="collapsed-title" onClick={footerNewsMenu}>
                      <i className="fa fa-angle-down float-right"></i>
                      {siteLang == "eng" ? languageText.newsHeadingEn : languageText.newsHeading}
                    </button>   
                  </div>
                </div>
                <div id="sm4" className={`sitemap-panel-collapse collapse ${footerNews}`}>
                  <div className="sitemap-panel-body">
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/dailysinglestock">
                        {siteLang == "eng" ? languageText.singleStockUpdateEn : languageText.singleStockUpdate}
                      </Link>
                    </div>
                    {/* <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/dailysp500">
                        {siteLang == "eng" ? languageText.dailySpEn : languageText.dailySp}
                      </Link>
                    </div> */}
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/dailyhsi">
                        {siteLang == "eng" ? languageText.dailyHsiEn : languageText.dailyHsi}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/todayhighlight">
                        {siteLang == "eng" ? languageText.todayHighlightEn : languageText.todayHighlight}
                      </Link>
                    </div>
                    {/* <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/dailycommentary">
                        {siteLang == "eng" ? languageText.dailyCommentryEn : languageText.dailyCommentry}
                      </Link>
                    </div> */}
                    {/* <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/overnightmarkets">
                        {siteLang == "eng" ? languageText.overnightMarketEn : languageText.overnightMarket}
                      </Link>
                    </div> */}
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/subscribe">
                        {siteLang == "eng" ? languageText.subscribeEn : languageText.subscribe}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/activities">
                        {siteLang == "eng" ? languageText.activitiesEn : languageText.activities}
                      </Link>
                    </div>
                    <div className="sitemap-row sitemap-panel">
                      <Link to="/marketnews/photogallery">
                        {siteLang == "eng" ? languageText.photoGalleryEn : languageText.photoGallery}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="hidden-xs hidden-sm" />
        <div className="row">
          <div className="sitemap-othr-xs visible-xs">
            <div className="sitemap-othr-col">
              <div><Link to="/about/importantinformation">{siteLang == "eng" ? languageText.importantInformationEn : languageText.importantInformation}</Link></div>
              <div><Link to="/about/privacypolicy">{siteLang == "eng" ? languageText.privacyPolicyEn : languageText.privacyPolicy}</Link></div>
              <div><Link to="/about/termsofuse">{siteLang == "eng" ? languageText.termsOfUseEn : languageText.termsOfUse}</Link></div>
            </div>
            <div className="sitemap-othr-col last">
              <div><Link to="/about/contactus">{siteLang == "eng" ? languageText.contactUsEn : languageText.contactUs}</Link></div>
              <div><Link to="/about/disclaimer">{siteLang == "eng" ? languageText.disclaimerEn : languageText.disclaimer}</Link></div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="col-sm-12 sitemap-othr-sm visible-sm">
            <p>
              <Link to="/about/importantinformation">{siteLang == "eng" ? languageText.importantInformationEn : languageText.importantInformation}</Link>
              |
              <Link to="/about/privacypolicy">{siteLang == "eng" ? languageText.privacyPolicyEn : languageText.privacyPolicy}</Link>
              |
              <Link to="/about/termsofuse">{siteLang == "eng" ? languageText.termsOfUseEn : languageText.termsOfUse}</Link>
              |
              <Link to="/about/contactus">{siteLang == "eng" ? languageText.contactUsEn : languageText.contactUs}</Link>
              |
              <Link to="/about/disclaimer">{siteLang == "eng" ? languageText.disclaimerEn : languageText.disclaimer}</Link>
            </p>
          </div>
          <div className="col-md-6 sitemap-corp">
            <p>
              {siteLang == "eng" ? languageText.rightsReservedEn : languageText.rightsReserved}
            </p>
          </div>
          <div className="col-md-6 sitemap-othr hidden-xs hidden-sm">
            <p>
              <Link to="/about/importantinformation">{siteLang == "eng" ? languageText.importantInformationEn : languageText.importantInformation}</Link>
              |
              <Link to="/about/privacypolicy">{siteLang == "eng" ? languageText.privacyPolicyEn : languageText.privacyPolicy}</Link>
              |
              <Link to="/about/termsofuse">{siteLang == "eng" ? languageText.termsOfUseEn : languageText.termsOfUse}</Link>
              |
              <Link to="/about/contactus">{siteLang == "eng" ? languageText.contactUsEn : languageText.contactUs}</Link>
              |
              <Link to="/about/disclaimer">{siteLang == "eng" ? languageText.disclaimerEn : languageText.disclaimer}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
